import { RootState } from '@store/types'
import { GetterTree } from 'vuex'

import { RouteState } from './types'

const getters: GetterTree<RouteState, RootState> = {
  name(state) {
    return state.name
  },
  meta(state) {
    return state.meta
  },
}

export default getters
