import { MutationTree } from 'vuex'

import { WorkState } from './types'

const mutations: MutationTree<WorkState> = {
  setWork(state, { data }) {
    state.work = data
  },
  setWorks(state, { data, options }) {
    const label: 'works' | 'worksInTop' = (options && options.label) || 'works'

    state[label] = data
  },
}

export default mutations
