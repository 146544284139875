import { NewsState } from './types'

const state = (): NewsState => ({
  newses: [],
  // newses: [
  //   {
  //     id: '1',
  //     title: '新しい建築',
  //     subTitle: '上尾の家',
  //     createdAt: '2021-10-01',
  //     body: 'パートナーである artless Inc.とともに、東京・水道橋にオープンした「toggle hotel suidobashi」のブランディング（ロゴ、V.I、グラフィック、サイネージ、ウェブデザイン）を担当。建築デザイン監修及びインテリアデザインはKDa（クライン ダイサム アーキテクツ）が担当。「on/off, your style」をコンセプトに、外観や全84室あるゲストルームが全てバイカラーでデザインされており、多彩な過ごし方ができる。',
  //     links: [
  //       {
  //         label: '建築アワードのページはこちら▶︎',
  //         href: 'https://www.google.co.jp/',
  //       },
  //       {
  //         label: '建築アワードのページはこちら▶︎',
  //         href: 'https://www.google.co.jp/',
  //       },
  //     ],
  //   },
  //   {
  //     id: '2',
  //     title: '新しい建築',
  //     subTitle: '上尾の家',
  //     createdAt: '2021-10-01',
  //     body: 'パートナーである artless Inc.とともに、東京・水道橋にオープンした「toggle hotel suidobashi」のブランディング（ロゴ、V.I、グラフィック、サイネージ、ウェブデザイン）を担当。建築デザイン監修及びインテリアデザインはKDa（クライン ダイサム アーキテクツ）が担当。「on/off, your style」をコンセプトに、外観や全84室あるゲストルームが全てバイカラーでデザインされており、多彩な過ごし方ができる。',
  //     imgs: [
  //       {
  //         src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //         alt: 'Apple Tower Theatre',
  //         width: 1325,
  //         height: 771,
  //         sources: [
  //           {
  //             srcset: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7?w=1000&q=120',
  //             media: '(max-width: 864px)',
  //             type: ImgMediaType.jpg,
  //           },
  //         ],
  //       },
  //       {
  //         src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //         alt: 'Apple Tower Theatre',
  //         width: 1325,
  //         height: 771,
  //         sources: [
  //           {
  //             srcset: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7?w=1000&q=120',
  //             media: '(max-width: 864px)',
  //             type: ImgMediaType.jpg,
  //           },
  //         ],
  //       },
  //     ],
  //     links: [
  //       {
  //         label: '建築アワードのページはこちら▶︎',
  //         href: 'https://www.google.co.jp/',
  //       },
  //       {
  //         label: '建築アワードのページはこちら▶︎',
  //         href: 'https://www.google.co.jp/',
  //       },
  //     ],
  //   },
  //   {
  //     id: '3',
  //     title: '新しい建築',
  //     subTitle: '上尾の家',
  //     createdAt: '2021-10-01',
  //     body: 'パートナーである artless Inc.とともに、東京・水道橋にオープンした「toggle hotel suidobashi」のブランディング（ロゴ、V.I、グラフィック、サイネージ、ウェブデザイン）を担当。建築デザイン監修及びインテリアデザインはKDa（クライン ダイサム アーキテクツ）が担当。「on/off, your style」をコンセプトに、外観や全84室あるゲストルームが全てバイカラーでデザインされており、多彩な過ごし方ができる。',
  //   },
  //   {
  //     id: '4',
  //     title: '新しい建築',
  //     subTitle: '上尾の家',
  //     createdAt: '2021-10-01',
  //     body: 'パートナーである artless Inc.とともに、東京・水道橋にオープンした「toggle hotel suidobashi」のブランディング（ロゴ、V.I、グラフィック、サイネージ、ウェブデザイン）を担当。建築デザイン監修及びインテリアデザインはKDa（クライン ダイサム アーキテクツ）が担当。「on/off, your style」をコンセプトに、外観や全84室あるゲストルームが全てバイカラーでデザインされており、多彩な過ごし方ができる。',
  //   },
  // ],
})

export default state
