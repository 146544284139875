import Vuex, { StoreOptions } from 'vuex'

import { news } from './news'
import { route } from './route'
import { RootState } from './types'
import { work } from './work'

const store: StoreOptions<RootState> = {
  state: () => ({
    version: '1.0.0',
  }),
  modules: {
    news,
    route,
    work,
  },
}

export default new Vuex.Store<RootState>(store)
