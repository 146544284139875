import { TextBodyType } from '@defines/text'

import { PostSimpleType, PostType } from './post'

export const WorkCategoryType = {
  space: 'Space',
  product: 'Product',
} as const

export type WorkCategoryType = typeof WorkCategoryType[keyof typeof WorkCategoryType]

export type WorkSimpleType = PostSimpleType & {
  category: WorkCategoryType
}

export type WorkType = PostType &
  WorkSimpleType & {
    introduction: TextBodyType
  }
