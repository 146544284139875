import { RootState } from '@store/types'
import { ActionTree } from 'vuex'

import { RouteState } from './types'

const actions: ActionTree<RouteState, RootState> = {
  setName({ commit }, { name }: { name: string }) {
    commit('setName', name)
  },
  setMeta({ commit }, { meta }) {
    commit('setMeta', meta)
  },
}

export default actions
