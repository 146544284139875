import { ImgType } from '@defines/img'
import { NewsSimpleType, NewsType } from '@defines/news'
import { RootState } from '@store/types'
import axiosWp from '@utils/axios-wp'
import { ActionTree } from 'vuex'

import { NewsState } from './types'

const actions: ActionTree<NewsState, RootState> = {
  async getNewses({ commit }) {
    const res = await Promise.all([axiosWp.get(`/news?_embed&filter[orderby]=date&order=asc`)])

    const newsesRes = res[0].data

    const newses: NewsType[] = await newsesRes.map(
      (newsRes: {
        slug: string
        title: { rendered: string }
        acf: {
          links: { linkLabel: string; linkUrl: string }[]
          imgs: never[]
          subTitle: string
          body: string
        }
        date: string
      }) => {
        const newsImgsRes = newsRes.acf.imgs || []
        const newsLinksRes = newsRes.acf.links || []

        const news: NewsSimpleType | NewsType = {
          id: newsRes.slug,
          title: newsRes.title.rendered,
          subTitle: newsRes.acf.subTitle,
          createdAt: newsRes.date,
          body: newsRes.acf.body,
          imgs: [],
          links: [],
        }

        newsImgsRes.forEach(
          (newsImgRes: { img: { url: string; alt: string; width: number; height: number; sizes: never } }) => {
            const img: ImgType = {
              src: newsImgRes.img.url,
              alt: newsImgRes.img.alt || newsRes.title.rendered,
              width: newsImgRes.img.width,
              height: newsImgRes.img.height,
              sources: [],
            }

            const imgSizes = newsImgRes.img.sizes

            let source = {
              srcset: '',
              media: '',
            }

            Object.keys(imgSizes).forEach((key) => {
              if (/-width$/.test(key)) {
                source.media = `(max-width: ${imgSizes[key] / 2}px)`
              } else if (!/-height$/.test(key)) {
                source.srcset = imgSizes[key]
              }

              if (source.srcset && source.media) {
                img.sources?.push(source)

                source = {
                  srcset: '',
                  media: '',
                }
              }
            })

            news.imgs?.push(img)
          }
        )

        newsLinksRes.forEach((newsLinkRes: { linkLabel: string; linkUrl: string }) => {
          news.links?.push({
            label: newsLinkRes.linkLabel,
            href: newsLinkRes.linkUrl,
          })
        })

        return news
      }
    )

    commit('setNewses', {
      data: newses,
    })
  },
}

export default actions
