import { NewsSimpleType, NewsType } from '@defines/news'
import { RootState } from '@store/types'
import { GetterTree } from 'vuex'

import { NewsState } from './types'

const getters: GetterTree<NewsState, RootState> = {
  newses(state): NewsSimpleType[] | NewsType[] {
    return state.newses
  },
}

export default getters
