import { MutationTree } from 'vuex'

import { RouteState } from './types'

const mutations: MutationTree<RouteState> = {
  setName(state, name) {
    state.name = name
  },
  setMeta(state, meta) {
    state.meta = meta
  },
}

export default mutations
