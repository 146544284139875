import { WorkSimpleType, WorkType } from '@defines/work'
import { RootState } from '@store/types'
import { GetterTree } from 'vuex'

import { WorkState } from './types'

const getters: GetterTree<WorkState, RootState> = {
  work(state): WorkType | undefined {
    return state.work
  },
  works(state): WorkSimpleType[] | WorkType[] {
    return state.works
  },
  worksTop(state): WorkSimpleType[] | WorkType[] {
    return state.worksInTop
  },
}

export default getters
