// import { ImgMediaType } from '@defines/img'
import { WorkCategoryType } from '@defines/work'

import { WorkState } from './types'

const state = (): WorkState => ({
  // work: undefined,
  work: {
    id: '1',
    title: {
      ja: '',
      en: '',
    },
    category: WorkCategoryType.space,
    createdAt: '2021-10-01',
    thumbnail: {
      src: '',
      alt: '',
      width: 638,
      height: 304,
    },
    tags: [],
    introduction: {
      ja: '',
      en: '',
    },
    body: {
      ja: '',
      en: '',
    },
    imgs: [],
    data: [],
    memo: {
      ja: '',
      en: '',
    },
  },

  works: [],
  // works: [
  //   {
  //     id: '1',
  //     title: {
  //       ja: 'アップル・タワー・シアター',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: 'アップル・タワー・シアター',
  //       width: 638,
  //       height: 304,
  //     },
  //   },
  //   {
  //     id: '2',
  //     title: {
  //       ja: '木のお家',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: '木のお家',
  //       width: 638,
  //       height: 304,
  //     },
  //   },
  //   {
  //     id: '3',
  //     title: {
  //       ja: 'ニュー・ソファー',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: 'ニュー・ソファー',
  //       width: 638,
  //       height: 304,
  //     },
  //   },
  //   {
  //     id: '4',
  //     title: {
  //       ja: '花瓶のある生活',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: '花瓶のある生活',
  //       width: 638,
  //       height: 304,
  //     },
  //   },
  //   {
  //     id: '5',
  //     title: {
  //       ja: '渡り廊下',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: '渡り廊下',
  //       width: 638,
  //       height: 304,
  //     },
  //   },
  //   {
  //     id: '6',
  //     title: {
  //       ja: '網テーブル',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: '網テーブル',
  //       width: 638,
  //       height: 304,
  //     },
  //   },
  // ],

  worksInTop: [],
  // worksInTop: [
  //   {
  //     id: '1',
  //     title: {
  //       ja: 'アップル・タワー・シアター',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: 'アップル・タワー・シアター',
  //       width: 638,
  //       height: 304,
  //     },
  //     tags: ['Architecture', 'Produce', 'Design'],
  //   },
  //   {
  //     id: '2',
  //     title: {
  //       ja: '木のお家',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1531554694128-c4c6665f59c2',
  //       alt: '木のお家',
  //       width: 638,
  //       height: 304,
  //     },
  //     tags: ['Architecture', 'Produce', 'Design'],
  //   },
  //   {
  //     id: '3',
  //     title: {
  //       ja: 'ニュー・ソファー',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1563203369-26f2e4a5ccf7',
  //       alt: 'ニュー・ソファー',
  //       width: 638,
  //       height: 304,
  //     },
  //     tags: ['Architecture', 'Produce', 'Design'],
  //   },
  //   {
  //     id: '4',
  //     title: {
  //       ja: '花瓶のある生活',
  //       en: 'Apple Tower Theatre',
  //     },
  //     category: WorkCategoryType.space,
  //     createdAt: '2021-10-01',
  //     thumbnail: {
  //       src: 'https://images.unsplash.com/photo-1531554694128-c4c6665f59c2',
  //       alt: '花瓶のある生活',
  //       width: 638,
  //       height: 304,
  //     },
  //     tags: ['Architecture', 'Produce', 'Design'],
  //   },
  // ],
})

export default state
