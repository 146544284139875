import { MutationTree } from 'vuex'

import { NewsState } from './types'

const mutations: MutationTree<NewsState> = {
  setNewses(state, { data, options }) {
    const label: 'newses' = (options && options.label) || 'newses'

    state[label] = data
  },
}

export default mutations
