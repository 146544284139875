<template>
  <div class="DividerBase" />
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.DividerBase {
  @include bg($color-other-divider);

  &.-colorLight {
    @include bg($color-other-divider-light);
  }

  &.-colorWhite {
    @include bg($color-white);
  }
}
</style>
