import dayjs from 'dayjs'
import { createSSRApp } from 'vue'
import { createWebHistory } from 'vue-router'
import vueScrollTo from 'vue-scrollto'

import DefaultLayout from '@layouts/default.vue'
import 'dayjs/locale/ja'

// import './registerServiceWorker'
import { createRouterHistory } from './router'
import store from './store'

import App from '@/App.vue'

dayjs.locale('ja')

require('destyle.css')
require('@/assets/scss/common/index.scss')

const app = createSSRApp(App)
const router = createRouterHistory(createWebHistory())

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.dispatch('route/setName', { name: to.name })
    store.dispatch('route/setMeta', { meta: to.meta })
  }
})

app.use(store).use(router).use(vueScrollTo)
app.provide('$dayjs', dayjs)

app.component('default-layout', DefaultLayout)

router.isReady().then(() => {
  app.mount('#app')
})
