import { ImgType } from '@defines/img'
import { WorkSimpleType, WorkType } from '@defines/work'
import { RootState } from '@store/types'
import axiosWp from '@utils/axios-wp'
import { ActionTree } from 'vuex'

import { WorkState } from './types'

const actions: ActionTree<WorkState, RootState> = {
  async getWork({ commit }, options) {
    const res = await Promise.all([axiosWp.get(`/work?_embed&slug=${options.slug}`)])

    const workRes = res[0].data[0]
    const workTagsRes = workRes.acf.tags || []
    const workImgsRes = workRes.acf.imgs || []
    const workProjectDatasRes = workRes.acf.projectDatas || []

    const work: WorkType = {
      id: workRes.slug,
      category: workRes._embedded['wp:term'][0][0].name,
      title: {
        ja: workRes.title.rendered,
        en: workRes.acf.enTitle,
      },
      createdAt: workRes.date,
      thumbnail: {
        src: workRes._embedded['wp:featuredmedia'][0].source_url,
        alt: workRes._embedded['wp:featuredmedia'][0].alt_text || `${workRes.title.rendered}のサムネイル`,
        width: workRes._embedded['wp:featuredmedia'][0].media_details
          ? workRes._embedded['wp:featuredmedia'][0].media_details.width
          : 0,
        height: workRes._embedded['wp:featuredmedia'][0].media_details
          ? workRes._embedded['wp:featuredmedia'][0].media_details.height
          : 0,
        sources: [],
      },
      tags: [],
      introduction: {
        ja: workRes.acf.introduction.introductionJa,
        en: workRes.acf.introduction.introductionEn,
      },
      body: workRes.acf.body.bodyJa
        ? {
            ja: workRes.acf.body.bodyJa,
            en: workRes.acf.body.bodyEn,
          }
        : undefined,
      imgs: [],
      data: [],
      memo: workRes.acf.memo.memoJa
        ? {
            ja: workRes.acf.memo.memoJa,
            en: workRes.acf.memo.memoEn,
          }
        : undefined,
    }

    workTagsRes.forEach((workTagRes: { tag: string }) => {
      work.tags?.push(workTagRes.tag)
    })

    workImgsRes.forEach(
      (workImgRes: { img: { url: string; alt: string; width: number; height: number; sizes: never } }) => {
        const img: ImgType = {
          src: workImgRes.img.url,
          alt: workImgRes.img.alt || workRes.title.rendered,
          width: workImgRes.img.width,
          height: workImgRes.img.height,
          sources: [],
        }

        const imgSizes = workImgRes.img.sizes

        let source = {
          srcset: '',
          media: '',
        }

        Object.keys(imgSizes).forEach((key) => {
          if (/-width$/.test(key)) {
            source.media = `(max-width: ${imgSizes[key] / 2}px)`
          } else if (!/-height$/.test(key)) {
            source.srcset = imgSizes[key]
          }

          if (source.srcset && source.media) {
            img.sources?.push(source)

            source = {
              srcset: '',
              media: '',
            }
          }
        })

        work.imgs?.push(img)
      }
    )

    workProjectDatasRes.forEach((workProjectDataRes: { projectDataLabel: string; projectDataData: string }) => {
      work.data?.push({
        label: workProjectDataRes.projectDataLabel,
        data: workProjectDataRes.projectDataData,
      })
    })

    commit('setWork', {
      data: work,
    })
  },
  async getWorks({ commit }, options) {
    const orderBy: string =
      (options && options.orderBy && `&filter[orderby]=${options.orderBy}`) || '&filter[orderby]=date'
    const length: string = (options && options.length && `&per_page=${options.length}`) || ''
    const category: string = (options && options.category && `&filter[category_work]=${options.category}`) || ''

    const res = await Promise.all([axiosWp.get(`/work?_embed${orderBy}&order=desc${category}${length}`)])

    const worksRes = res[0].data

    const works: WorkSimpleType[] = await worksRes.map(
      (workRes: {
        slug: string
        _embedded: { [x: string]: any } // eslint-disable-line
        title: { rendered: string }
        acf: { enTitle: string }
        date: string
      }) => {
        const work: WorkSimpleType = {
          id: workRes.slug,
          category: workRes._embedded['wp:term'][0][0].name,
          title: {
            ja: workRes.title.rendered,
            en: workRes.acf.enTitle,
          },
          createdAt: workRes.date,
          thumbnail: {
            src: workRes._embedded['wp:featuredmedia'][0].source_url,
            alt: workRes._embedded['wp:featuredmedia'][0].alt_text || `${workRes.title.rendered}のサムネイル`,
            width: workRes._embedded['wp:featuredmedia'][0].media_details
              ? workRes._embedded['wp:featuredmedia'][0].media_details.width
              : 0,
            height: workRes._embedded['wp:featuredmedia'][0].media_details
              ? workRes._embedded['wp:featuredmedia'][0].media_details.height
              : 0,
            sources: [],
          },
        }

        const workMediaSizes =
          (workRes._embedded['wp:featuredmedia'][0].media_details &&
            workRes._embedded['wp:featuredmedia'][0].media_details.sizes) ||
          undefined

        if (options.label !== 'worksInTop' && workMediaSizes) {
          Object.keys(workMediaSizes).forEach((key) => {
            if (workMediaSizes[key].source_url) {
              work.thumbnail?.sources?.push({
                srcset: workMediaSizes[key].source_url,
                type: workMediaSizes[key].mime_type,
                media: `(max-width: ${workMediaSizes[key].width / 3 || 0}px)`,
              })
            }
          })
        }

        return work
      }
    )

    commit('setWorks', {
      data: works,
      options: { label: options && options.label ? options.label : undefined },
    })
  },
}

export default actions
