import store from '@store/index'
import { createRouter, createWebHistory, Router, RouteRecordRaw, RouterHistory, RouterScrollBehavior } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Top',
    component: () => import('@pages/Index.vue'),
    meta: {
      headerClass: '-colorWhite -logoHide',
      footerClass: '-isHide',
      pageTopClass: '-isHide',
    },
  },
  {
    path: '/works/',
    name: 'Works',
    component: () => import('@pages/works/Index.vue'),
    beforeEnter: (to, _, next) => {
      if (to.query.category) {
        next()
      } else {
        next('/works/?category=all')
      }
    },
  },
  {
    path: '/works/:id/',
    name: 'Works - {{ workTitle }}',
    component: () => import('@pages/works/_workId/Index.vue'),
  },
  {
    path: '/about/',
    name: 'About',
    component: () => import('@pages/about/Index.vue'),
  },
  {
    path: '/news/',
    name: 'News',
    component: () => import('@pages/news/Index.vue'),
  },
]

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else if (to.hash) {
    return {
      selector: to.hash,
      // offset: { x: 0, y: 10 }
    }
  }

  return { top: 0 }
}

export function createRouterHistory(history: RouterHistory): Router {
  return createRouter({
    history,
    routes,
    scrollBehavior,
  })
}

const router = createRouterHistory(createWebHistory(process.env.BASE_URL))

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.dispatch('route/setName', { name: to.name })
    store.dispatch('route/setMeta', { meta: to.meta })
  }
})

export default router
